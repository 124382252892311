<template>
  <!-- Button trigger modal -->
  <!--<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalBanner">
      Launch demo modal
  </button>-->

  <!-- Modal -->

  <!-- GAME INTERFACE-->
  <div class="modal overflow-hidden" id="modalBannerLottery" tabindex="-1" aria-labelledby="modalBannerLabel" aria-hidden="true">
      <transition name="fadeOut">
          <div class="modal-dialog modal-dialog-centered" v-show="isSelected==1">
              <!-- INITIAL GAME INTERFACE-->
              <div class="modal-content modal-lottery--background__color lottery-modal col-12"
                  :class="{ 'lottery-modal--hide': isSelected }">
                  <div class="text-end">
                      <button type="button" class="btn-close p-2 position-absolute end-0" data-bs-dismiss="modal"
                          aria-label="Close" id="closeBtn"></button>
                  </div>
                  <div class="modal-body p-0 modal-lottery--background__image text-center text-white">
                      <!-- <img src="../assets/images/lottery/back-to-school/lbl-01.png" alt=""
                          class="img-fluid pb-3 pt-3"> -->
                      <h4 class="montserrat-lbl-01 pt-5 mt-5">RING THE BELL <br> IN THE NAME OF</h4>
                      <h1 class="montserrat-lbl-02">MEXICAN <br> INDEPENDENCE</h1>
                      <img src="../assets/images/campaigns/independence-day-2024/bell.png" alt="" class="w-50"
                          :class="[isRinging ? 'swing--selected-state' : '', 'swing--initial-state']"
                          @click="setSelected()">
                      <div class="row m-0 pt-5">
                          <div class="col text-start montserrat-lbl-01 lbl-03 justify-content-center align-content-center p-">
                              Does not apply with other promotions.
                              Valid with the purchase a
                              Vacation Package to Mexico.
                              •only with the purchase of a 9599 package.
                          </div>
                          <div class="col d-flex justify-content-end p-0">
                              <img src="../assets/images/campaigns/independence-day-2024/logo.png" alt="" class="logo--w">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </transition>

<!-- PRIZE INTERFACE-->
      <transition name="fadeIn">
          <div class="modal-dialog modal-dialog-centered" v-if="isSelected==2">
              <div class="modal-content modal-lottery--background__color prize-modal col-12">
                  <div class="modal-header modal-header--prize text-center border-0">
                      <div class="col">
                          <h4 class="modal-title modal-title--prize text-white text-uppercase">Congrats!</h4>
                      </div>
                      <button type="button" class="btn-close p-2" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body p-0 text-center text-white">
                      <img :src="prize.imgUrl" alt="" class="w-50 py-3 py-lg-5"
                          :class="{ 'prize-up': isSelected }">
                      <p class="montserrat-lbl-01 lbl-terms">*Valid with the purchase of a vacation package to Mexico.
                      </p>
                      <the-lottery-form :prizeCode="prize.code" :prizeName="prize.name" />
                      <h4 class="lbl-redem pt-3 pt-lg-0">TO REDEEM YOUR PRIZE!</h4>
                  </div>
              </div>
          </div>
      </Transition>

<!-- FINAL INTERFACE-->
<transition name="fadeIn">
          <div class="modal-dialog modal-dialog-centered" v-if="isSelected == 3">
              <div class="modal-content modal-lottery--background__color prize-modal col-12">
                  <div class="modal-header modal-header--prize text-center">
                      <div class="col">
                          <h4 class="modal-title modal-title--prize">THANK YOU!</h4>
                      </div>
                      <button type="button" class="btn-close p-2" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body p-0 text-center text-white">
                      
                      <h4 class="lbl-redem p-5 pb-2">FOR PLAYING AND <br> REGISTERING<br> YOUR INFORMATION </h4>
                      <h4 class="montserrat-lbl-01 pb-5 lbl-05">A VACATION EXPERT WILL <br> CONTACT YOU SHORTLY</h4>
                      <!-- <img src="../assets/images/lottery/back-to-school/footer-img.png" alt="" class="img-fluid"> -->
                  </div>
              </div>
          </div>
      </Transition>

  </div>
  <div class="position-fixed bottom-0 end-0 w-100 validation-toast" >
        <div class="d-lg-flex justify-content-end">
            <div id="liveToast" class="toast text-bg-danger w-100" role="alert" aria-live="assertive"  aria-atomic="true" data-bs-delay="3000"> 
                <div class="toast-header">
                  <strong class="me-auto pe-3">Please provide this information</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TheLotteryForm from './TheLotteryForm.vue';
import Confetti from 'canvas-confetti'
import { useWebsiteStore } from '@/store/website';

export default {
  data() {
      return {
          bannerUrl: "",
          now: new Date(),
          fromDate: new Date(2024, 8, 6, 0, 0, 0),
          toDate: new Date(2024, 8, 18, 23, 59, 59),
          fromSecondDate: new Date(2024, 2, 11, 0, 0, 0),
          toSecondDate: new Date(2024, 2, 31, 23, 59, 59),
          now: new Date(),
          isSelected: 1,
          isRinging: false,
          prize:
          {
              name: '',
              code: '',
              imgUrl: '',
          }
      }
  },
  components: {
      TheLotteryForm
  },
  methods: {
      showModal() {
          let bannerModal = document.querySelector('#modalBannerLottery');
          console.log(bannerModal)
          //eslint-disable-next-line
          const bannerModalInstance = new bootstrap.Modal(bannerModal);
          bannerModalInstance.show();
      },
      setSelected() {
          this.isRinging = true
          setTimeout(() => {
              this.isSelected = 2
              this.getConfetti
          }, 1000);
      },
    setRandomNumber(max) {
      return Math.floor(Math.random() * max);
    },
    setPrize() {
      switch (this.setRandomNumber(4)) {
        case 0:
          this.prize.name = 'FREE CRUISE',
            this.prize.code = '#VALCRUISE',
            this.prize.imgUrl = require('@/assets/images/campaigns/independence-day-2024/prizes/prize-01.png')
          break;
        case 1:
          this.prize.name = '$100 USD DISCOUNT',
            this.prize.code = '#B100DIS',
            this.prize.imgUrl = require('@/assets/images/campaigns/independence-day-2024/prizes/prize-02.png')
          break;
        case 2:
          this.prize.name = 'FREE VACATION LAS VEGAS',
            this.prize.code = '#VALVEGAS',
            this.prize.imgUrl = require('@/assets/images/campaigns/independence-day-2024/prizes/prize-03.png')
          break;
        case 3:
          this.prize.name = '$75 usd DISCOUNT',
            this.prize.code = '#VAL75DIS',
            this.prize.imgUrl = require('@/assets/images/campaigns/independence-day-2024/prizes/prize-04.png')
          break;
        case 4:
          this.prize.name = '$50 usd DISCOUNT',
            this.prize.code = '#VAL50DIS',
            this.prize.imgUrl = require('@/assets/images/campaigns/independence-day-2024/prizes/prize-05.png')
          break;
        // case 2:
        //     this.prize.name = '2X1 GOLF ROUND',
        //         this.prize.code = '#B2X1GOLF',
        //         this.prize.imgUrl = 'images/lottery/back-to-school/prizes/prize-03.png'
        //     break;
        default:
          break;
      }
    }
  },
  computed: {
      getBanner() {
          if (this.$i18n.locale == "eng") {
              this.bannerUrl = 'images/campaigns/banners/banner-fourthjuly.png'
          } else {
              this.bannerUrl = 'images/campaigns/banners/banner-fourthjuly.png'
          }
          return this.bannerUrl
      },
      getConfetti() {
        Confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
          zIndex: 2000,
          shapes: ['circle'],
          colors: ['#007728', '#d90200', '#ffff']
        })
      }
  },
  setup() {
    const websiteStore = useWebsiteStore();
      const getImageUrl = (name) => {
          return new URL(`../assets/${name}`, import.meta.url).href
      }
      return {
          getImageUrl, websiteStore
      }
  },
  mounted() {
   
      if (this.websiteStore.getIsCampaign) {
          this.setPrize();
          this.showModal();
      }
  }
}

</script>

<style>
.modal-lottery--background__color {
  background: rgb(0,135,46);
  background: radial-gradient(circle, rgba(0,135,46,1) 0%, rgba(0,44,9,1) 100%);
}

.modal-lottery--background__image {
    background-image: url(../assets/images/campaigns/independence-day-2024/bg-01.png);
    background-size: 138%;
    background-position: center;
}

.btn-close {
  opacity: 1 !important;
  z-index: 1 !important;
  background-color: white;
}

.modal-backdrop {
  z-index: -1;
}

.montserrat-lbl-01 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.montserrat-lbl-02 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
}


.lbl-03 {
  font-size: 10px;
}

.lbl-05 {
  color: #F1A619;;
}

#modalBannerLottery {
  background-color: rgba(0, 0, 0, 0.5);
}

.logo--w {
  width: 43%;
}

.validation-toast {
  z-index: 2000!important;
}
/* --------------- ANIMATION ------------*/

.swing--initial-state {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float: center;

}

.swing--selected-state {
  animation: swing ease-in-out .1s infinite alternate;
  transform-origin: center -20px;
  float: center;

}

.swing img {
  border: 5px solid #f8f8f8;
  display: block;
}

.swing:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  top: -10px;
  left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}

/* nail */
.swing:before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  top: -14px;
  left: 54%;
  z-index: 5;
  border-radius: 50% 50%;
  background: #000;
}

@keyframes swing {
  0% {
      transform: rotate(3deg);
  }

  100% {
      transform: rotate(-3deg);
  }
}

/*Display transition*/

/* .lottery-modal {
  transition: visibility 1.5s, opacity 1.5s;
  transition-delay: 1s;
}

.lottery-modal--hide {
  visibility: hidden;
  opacity: 0;
} */

/* .prize-modal{
  visibility: hidden;
  opacity: 0;
  transition: visibility 5s, opacity 5s;
  transition-delay: 1s;
}

.prize-modal--show {
  visibility: visible;
  opacity: 1;
} */

/*---------------------- */

.modal-header--prize {
  background: rgb(186,13,19);
  background: linear-gradient(180deg, rgba(186,13,19,1) 0%, rgba(217,30,37,1) 100%);
}

.modal-title--prize {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 2.2rem;
  color: #173e5d;
}

.lbl-terms {
  font-size: 12px;
}

.lbl-redem {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: italic;
}

/*----Vue Transition----------- */
.fadeOut-enter-active,
.fadeOut-leave-active {
  transition: opacity .5s ease;
}

.fadeOut-enter-from,
.fadeOut-leave-to {
  opacity: 0;
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 1.5s ease-out;
}

.fadeIn-enter-from,
.fadeIn-leave-to {
  opacity: 0;
}

/*--------------- Prize Animation ------------*/
.prize-down {
  animation-duration: 2s;
  animation-name: slideindown;
}

.prize-up {
  animation-duration: 2s;
  animation-name: slideinup;
}

@keyframes slideindown {
  from {
      translate: 0 -150vw;
      scale: 200% 1;
  }

  to {
      translate: 0 0;
      scale: 100% 1;
  }
}
@keyframes slideinup {
  from {
      translate: 0 150vw;
      scale: 200% 1;
  }

  to {
      translate: 0 0;
      scale: 100% 1;
  }
}
</style>
